import styled from 'styled-components';
import { MultiLineInput } from '../elements/Input';
import { H3, LabelUppercase, P } from '../elements/Text';
import { RadioButton } from '../elements/SelectionInputs';

const Difficulty = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
`;

const DifficultyValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const DifficultyLabelNatural = styled(LabelUppercase)``;

const DifficultyValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 768px) {
    flex-direction: column;
    width: initial;
    margin-bottom: 0;
  }
`;

// overriding global theme defaults
const DifficultyRadioButton = styled(RadioButton)`
  width: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 0 !important;
`;

const DifficultyLabelNumber = styled.label`
  margin: 0;
  padding-right: 0 !important;
`;

const difficultyLabelMap: { [key: number]: string } = {
  1: 'too easy',
  3: 'just right',
  5: 'too hard',
};

const Feedback = ({
  feedback,
  onFeedbackChange,
  difficultyRating,
  onDifficultyChange,
}: {
  feedback: string;
  onFeedbackChange: (feedback: string) => void;
  difficultyRating: number | null;
  onDifficultyChange: (difficultyRating: number) => void;
}) => {
  return (
    <>
      <H3>Any questions or feedback?</H3>
      <MultiLineInput
        value={feedback}
        placeholder="Any comments for your AI mentor?"
        onChange={(e) => onFeedbackChange(e.target.value)}
      />
      <P>How difficult was this activity?</P>
      <Difficulty>
        <DifficultyValues>
          {[1, 2, 3, 4, 5].map((d) => (
            <DifficultyValueWrapper key={`activity-feedback-value-${d}`}>
              {[1, 3, 5].includes(d) && (
                <DifficultyLabelNatural>
                  {difficultyLabelMap[d]}
                </DifficultyLabelNatural>
              )}
              <DifficultyRadioButton
                key={d}
                required
                type="radio"
                name="difficultyRating"
                value={d}
                checked={d === difficultyRating}
                readOnly
              />
              <DifficultyLabelNumber onClick={() => onDifficultyChange(d)}>
                {d}
              </DifficultyLabelNumber>
            </DifficultyValueWrapper>
          ))}
        </DifficultyValues>
      </Difficulty>
    </>
  );
};

export default Feedback;
