import { DefaultTheme } from 'styled-components';
import { mixColors } from './utils';

const theme: DefaultTheme = {
  colors: {
    black: '#000000',
    bgPage: '#09090B',
    bgDefault: '#FFFFFF1A',
    bgDefaultSecondary: '#FFFFFF0D',
    bgActive: '#FFFFFF1A',

    navigation: '#27272A',
    divider: '#FFFFFF1A',
    textPrimary: '#FAFAFA',
    textRegular: '#A1A1AA',
    textDisabled: '#FFFFFF1A',
    textActive: '#E4E4E7', // for tabs
    textPlaceholder: '#A1A1AA66',
    textPrimaryInformation: '#09090B',

    inputBackground: mixColors('#09090B', '#ffffff', 0.1),
    inputBorderActive: mixColors('#09090B', '#ffffff', 0.15),

    inputTextPlaceholder: mixColors('#09090B', '#A1A1AA', 0.4),
    inputTextActive: '#FAFAFA',

    fieldBackground: '#3F3F46',
    buttonPrimary: '#1DEA99',
    buttonSecondary: '#FCB61D',
    buttonTextColor: '#09090B',

    zinc800: '#27272A',
    primary: '#1DEA99',
    secondary: '#FCA92A',

    highlight: '#F271CD',

    highlightBg: '#564065',
    transparentBg: 'rgba(242, 245, 250, 0.04)',

    text: '#ffffff',
    white: '#ffffff',
    textDefault: '#a7aec8',
    textTransparent: 'rgba(255, 255, 255, 0.6)',
    textSecondary: '#F2F5FA',

    // older colors
    primaryBorder: '#3beba8',

    // Mixed backgrounds
    primaryBg: 'rgba(59, 235, 168, 0.1)',
    secondaryBg: 'rgba(252, 169, 42, 0.1)',

    textDanger: '#E54073',
    textSuccess: '#24CB8C',

    sectionBorder: '#585e7e',
    sectionDivider: '#7f89bb',

    elementBgSuccess: 'rgba(59, 235, 168, 0.16)',
    elementBgDanger: 'rgba(229, 64, 115, 0.16)',

    bgDanger: 'rgba(229, 64, 115, 0.16);',
  },

  dimensions: {
    sectionBorderRadius: '8px',
    readableColumnWidth: '680px',
    largeColumnWidth: 'calc(100vw + (2000px / 3))', // full width - 2 margins (computed at 1/3 screen - readable content (nav + readable col width))
    spacing: {
      default: {
        top: '32px',
        bottom: '12px',
      },
    },
  },

  fonts: {
    playground: `IBM Plex Mono, source-code-pro, monospace`,
  },

  shadows: {
    default: '0px 0px 20px rgba(0, 0, 0, 0.4)',
  },
};

export default theme;
