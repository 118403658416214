import styled from 'styled-components';
import { H3, P } from '../../elements/Text';
import {
  AppliedProjectStat,
  LearningPlan,
  ProsumerContentModule,
} from '../../../hydra';
import LearningPlanUnitCard from './LearningPlanUnitCard';
import {
  ShimmerDiv,
  SkeletonDiv,
  SkeletonLoaderWrapper,
} from '../../SkeletonLoader';

const PlanWrapper = styled.div`
  border-radius: 8px;
  padding: 0;
  margin-top: 32px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export type LearningPlanUnit = {
  status: 'not-started' | 'in-progress' | 'completed';
  reasoning: string;
  slug: string;
} & (
  | {
      type: 'project';
      slug: string;
      project: AppliedProjectStat;
    }
  | {
      type: 'content-module';
      slug: string;
      pcm: ProsumerContentModule;
      reasoning: string;
      status: 'not-started' | 'in-progress' | 'completed';
    }
);

const PlanCardsWrapper = styled.div`
  display: flex;
  gap: 0px;
  width: 100%;
  margin: 28px 0px;
  flex-direction: column;
`;

const SkeletonBar = styled(SkeletonDiv)`
  height: 82px;
  width: 100%;
`;
export default function LearningPlanCardModal({
  learningPlan,
  projects,
  prosumerContentModules,
}: {
  learningPlan?: LearningPlan;
  projects?: Array<AppliedProjectStat>;
  prosumerContentModules?: Array<ProsumerContentModule>;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <H3>Your Learning Plan</H3>
      <PlanWrapper>
        {learningPlan && projects && prosumerContentModules ? (
          <LearningPlanCard
            learningPlan={learningPlan}
            projects={projects}
            prosumerContentModules={prosumerContentModules}
          />
        ) : (
          <SkeletonLoaderWrapper>
            <ShimmerDiv />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
            <SkeletonBar />
          </SkeletonLoaderWrapper>
        )}
      </PlanWrapper>
    </div>
  );
}

function LearningPlanCard({
  learningPlan,
  projects,
  prosumerContentModules,
}: {
  learningPlan: LearningPlan;
  projects: Array<AppliedProjectStat>;
  prosumerContentModules: Array<ProsumerContentModule>;
}) {
  const planWithInfo: Array<LearningPlanUnit> = learningPlan
    .map((unit) => {
      if (unit.type === 'project') {
        const project = projects.find((p) => p.slug === unit.slug);

        if (!project) {
          return null;
        }

        console.log('project', project);
        return {
          type: 'project',
          slug: unit.slug,
          project: project,
          reasoning: unit.reasoning,
          status: !project.is_submitted
            ? 'not-started'
            : project.completedQuestions === project.question_count
            ? 'completed'
            : 'in-progress',
        };
      } else {
        const module = prosumerContentModules.find(
          (pcm) => pcm.content_slug === unit.slug
        );
        if (!module) {
          return null;
        }
        return {
          type: 'content-module',
          slug: unit.slug,
          pcm: module,
          reasoning: unit.reasoning,
          status: !module.has_submission
            ? 'not-started'
            : module.lessons_count === module.completed_lessons_count
            ? 'completed'
            : 'in-progress',
        };
      }
    })
    .filter((unit) => unit !== null) as Array<LearningPlanUnit>;

  return (
    <>
      <P style={{ marginTop: 0 }}>
        ✨ We’ve generated this unique learning plan based on your goals and
        profile.
        <br />
        Keep learning and check back for updates!
      </P>
      <PlanCardsWrapper>
        {planWithInfo.map((unit, ind) => (
          <LearningPlanUnitCard
            key={unit.slug}
            learningUnit={unit}
            index={ind + 1}
            numItems={planWithInfo.length}
          />
        ))}
      </PlanCardsWrapper>
    </>
  );
}
