import React from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

export default function usePythonRunner({
  onOutput,
  onResult,
  shouldPreloadPyodide,
}) {
  const pyServiceWorkerRef = React.useRef(null);
  const pyWebWorkerRef = React.useRef(null);
  const [isWebWorkerReady, setIsWebWorkerReady] = React.useState(false);
  const [isServiceWorkerReady, setIsServiceWorkerReady] = React.useState(false);
  const [base64EncodedPlot, setBase64EncodedPlot] = React.useState();
  const [isReady, setIsReady] = React.useState(false);
  const [isRunning, setIsRunning] = React.useState(false);
  const [isAwaitingInput, setIsAwaitingInput] = React.useState(false);
  const [codeQueue, setCodeQueue] = React.useState('');
  const [deviceSupportsWorkers, setDeviceSupportsWorkers] =
    React.useState(true);

  const playgroundId = React.useMemo(() => uuidv4(), []);

  const [output, setOutput] = React.useState('');

  const appendToOutput = React.useCallback(
    (data) => {
      setOutput((prevOutput) => prevOutput + data);
    },
    [setOutput]
  );

  useUpdateEffect(() => {
    if (!isRunning) {
      onResult(output);
    }
    // leaving out onResult, as this triggers an infinite loop
  }, [isRunning, output]);

  const initWebWorker = React.useCallback(() => {
    setIsWebWorkerReady(false);
    if (pyWebWorkerRef.current) {
      pyWebWorkerRef.current.terminate();
    }
    pyWebWorkerRef.current = new Worker(
      process.env.PUBLIC_URL + '/py-web-worker.js'
    );

    pyWebWorkerRef.current.onmessage = (event) => {
      if (event.data.type === 'stdout' || event.data.type === 'stderr') {
        onOutput(event?.data?.output);
        appendToOutput(event?.data?.output);
      } else if (event.data.type === 'ready') {
        pyWebWorkerRef.current.postMessage({
          type: 'register-playground-id',
          playgroundId,
          shouldInitializePyodide: shouldPreloadPyodide,
        });
        setIsWebWorkerReady(true);
      } else if (event.data.type === 'error') {
        onOutput(event?.data?.output);
        appendToOutput(event?.data?.output);
        setIsRunning(false);
      } else if (event.data.type === 'finish-execution') {
        setIsRunning(false);
      } else if (event.data.type === 'logger') {
        console.log(event.data.message);
      } else if (event.data.type === 'set-plot-image') {
        setBase64EncodedPlot(`data:image/png;base64,${event.data.base64Image}`);
      }
    };
  }, [onOutput, playgroundId, appendToOutput, shouldPreloadPyodide]);

  const initServiceWorker = React.useCallback(async () => {
    setIsServiceWorkerReady(false);

    if ('serviceWorker' in navigator) {
      console.log(
        "Service workers supported, registering 'py-service-worker.js'"
      );
      console.log('Registering service worker at: ' + process.env.PUBLIC_URL);
      const swRegistration = await navigator.serviceWorker.register(
        process.env.PUBLIC_URL + '/py-service-worker.js'
      );
      console.log(
        "Service worker registered, scope: '" + swRegistration.scope + "'"
      );
      const onServiceWorkerStateChange = () => {
        if (swRegistration.active?.state === 'activated') {
          pyServiceWorkerRef.current = swRegistration.active;
          setIsServiceWorkerReady(true);
        }
      };

      if (swRegistration.active) {
        if (swRegistration.active?.state === 'activating') {
          swRegistration.active.addEventListener(
            'statechange',
            onServiceWorkerStateChange
          );
        } else {
          onServiceWorkerStateChange();
        }
      } else if (swRegistration.installing) {
        swRegistration.installing.addEventListener(
          'statechange',
          onServiceWorkerStateChange
        );
      } else if (swRegistration.waiting) {
        swRegistration.waiting.addEventListener(
          'statechange',
          onServiceWorkerStateChange
        );
      }

      navigator.serviceWorker.onmessage = (event) => {
        if (event.data.type === 'py-input-await') {
          if (event.data.playgroundId === playgroundId) {
            setIsAwaitingInput(true);
          }
        }
      };
    } else {
      console.log('Service workers not supported');
      setDeviceSupportsWorkers(false);
    }
  }, [playgroundId]);

  useUpdateEffect(() => {
    if (isServiceWorkerReady && !isWebWorkerReady) {
      initWebWorker();
    }

    return () => {
      if (pyWebWorkerRef.current) {
        pyWebWorkerRef.current.terminate();
        pyWebWorkerRef.current = null;
      }
    };
  }, [isServiceWorkerReady]);

  useEffectOnce(() => {
    initServiceWorker();
    return () => {
      if (pyServiceWorkerRef.current && pyServiceWorkerRef.current.unregister) {
        pyServiceWorkerRef.current.unregister();
        pyServiceWorkerRef.current = null;
      }
    };
  }, [initServiceWorker]);

  React.useEffect(() => {
    if (isWebWorkerReady && isServiceWorkerReady) {
      setIsReady(true);
    }
  }, [isWebWorkerReady, isServiceWorkerReady]);

  const runCode = React.useCallback(
    (code) => {
      if (deviceSupportsWorkers) {
        setIsRunning(true);
        setCodeQueue(code);
      } else {
        onOutput('Playground not supported on your device. Try on desktop!');
      }
    },
    [deviceSupportsWorkers, onOutput]
  );

  React.useEffect(() => {
    if (isReady && codeQueue) {
      setOutput('');
      pyWebWorkerRef.current.postMessage({
        type: 'run-code',
        code: codeQueue,
        shouldInitializePyodide: !shouldPreloadPyodide,
      });
      setCodeQueue(null);
    }
  }, [isReady, codeQueue, shouldPreloadPyodide]);

  const sendInput = React.useCallback(
    (input) => {
      if (isReady) {
        pyServiceWorkerRef.current.postMessage({
          type: 'py-input-receive',
          value: input,
          playgroundId,
        });
        appendToOutput(input + '\n'); // emulate the stdout flush at the end of the input
        setIsAwaitingInput(false);
      }
    },
    [isReady, playgroundId, setIsAwaitingInput, appendToOutput]
  );

  const interruptExecution = React.useCallback(() => {
    if (isReady) {
      setIsAwaitingInput(false);
      setIsRunning(false);
      pyWebWorkerRef.current.terminate();
      pyWebWorkerRef.current = null;
      initWebWorker();
    }
  }, [isReady, initWebWorker]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isReady && deviceSupportsWorkers) {
        console.error('Python runner failed to initialize');
      }
    }, 25000);

    return () => clearTimeout(timeout);
  }, [isReady, deviceSupportsWorkers]);

  return {
    isLoading: !isReady,
    isRunning,
    isAwaitingInput,
    runCode,
    sendInput,
    interruptExecution,
    base64EncodedPlot,
  };
}
