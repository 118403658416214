import styled from 'styled-components';
// Input for either checkboxes or radios
const MultiSelectInputBase = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;

  &:checked + label:before {
    background: rgba(59, 235, 168, 0.16);
    border-color: rgba(59, 235, 168, 0.16);
    color: #fff;
    font-family: FontAwesome !important;
    font-weight: 600;
    content: '\\f00c';
  }

  &:focus + label:before {
    border-color: #ffffff;
    box-shadow: 0 0 0 1px #ffffff;
  }

  & + label {
    text-decoration: none;
    color: #70ffbc;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: normal;
    padding-left: 2.4em;
    padding-right: 0.75em;
    position: relative;

    &:before {
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-transform: none !important;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 0.25em;
      border: solid 1px rgba(255, 255, 255, 0.15);
      content: '';
      display: inline-block;
      height: 1.65em;
      left: 0;
      line-height: 1.58125em;
      position: absolute;
      text-align: center;
      top: 0;
      width: 1.65em;
    }
  }
`;

export const RadioButton = styled(MultiSelectInputBase).attrs({
  type: 'radio',
})`
  & + label:before {
    border-radius: 100%;
  }
`;

export const Checkbox = styled(MultiSelectInputBase).attrs({
  type: 'checkbox',
})`
  & + label:before {
    border-radius: 0.25em;
  }
`;
