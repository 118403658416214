import styled, { keyframes } from 'styled-components';

export const shimmerAnim = keyframes`
0% {
  transform: translateX(-200%);
}
100% {
  transform: translateX(200%);
}
`;
export const SkeletonLoaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: between;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
  gap: 8px;
  border-radius: 8px;
`;
export const ShimmerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    100deg,
    transparent 20%,
    rgba(200, 200, 200, 0.15) 50%,
    transparent 80%
  );
  animation linear ${shimmerAnim} 2s infinite;
`;

export const SkeletonDiv = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bgActive};
`;
