import React from 'react';
import { QuestionPayload } from '../../../hydra';
import styled from 'styled-components';
import { RadioButton } from '../../elements/SelectionInputs';

const MultipleChoiceOptionWrapper = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  position: relative;
  margin-top: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition: all 1s ease;

  p {
    margin: 0;
    padding: 0;
  }

  input {
    position: fixed;
  }
`;

const MultipleChoiceSectionWrapper = styled.div`
  width: 100%;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 42px;
`;

const OptionLabel = styled.label<{ isCorrect: boolean; isSelected: boolean }>`
  margin: 0;
  color: ${({ theme }) => theme.colors.textRegular} !important;
  font-size: 20px !important;

  &:before {
    ${({ theme, isSelected, isCorrect }) =>
      isSelected
        ? `
    color: ${theme.colors.black} !important;
    background-color: ${
      isCorrect ? theme.colors.buttonPrimary : theme.colors.buttonSecondary
    } !important;
    content: ${isCorrect ? "'\f00c'" : "'\f00d'"} !important;
    `
        : ''}
  }
`;

export default function MultipleChoiceSelector({
  question,
  selectedAnswer,
  onAnswerChange,
}: {
  question: QuestionPayload;
  selectedAnswer: string;
  onAnswerChange: (answer: string) => void;
}) {
  const options = React.useMemo(() => {
    return [
      ...(question.multiple_choice_answers?.distractors ?? []),
      question.multiple_choice_answers?.correct_answer ?? '',
    ].sort(() => Math.random() - 0.5);
  }, [question]);

  return (
    <MultipleChoiceSectionWrapper>
      {options.map((option, i) => (
        <MultipleChoiceOptionWrapper key={i}>
          <RadioButton
            name="multiple-choice"
            value={option}
            checked={selectedAnswer === option}
            readOnly
          />
          <OptionLabel
            onClick={() => onAnswerChange(option)}
            isCorrect={
              option === question.multiple_choice_answers?.correct_answer
            }
            isSelected={selectedAnswer === option}
          >
            {option}
          </OptionLabel>
        </MultipleChoiceOptionWrapper>
      ))}
    </MultipleChoiceSectionWrapper>
  );
}
