import styled from 'styled-components';
import theme from '../../theme';
import { H3, LabelUppercase } from '../elements/Text';
import { useStreakStats } from '../../hooks/use-hydra';
import {
  SkeletonLoaderWrapper,
  ShimmerDiv,
  SkeletonDiv,
} from '../SkeletonLoader';

const Card = styled.div`
  background-color: ${theme.colors.bgActive};
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${theme.colors.bgActive};
  padding-top: 0px;
  min-height: 250px;
  @media screen and (max-width: 982px) {
    grid-column: span 2;
  }
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 40px;
  flex-grow: 1;
`;

const BottomRow = styled.div`
  background-color: ${({ theme }) => theme.colors.bgActive};
  height: 100px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0px 20px;
`;

const StatCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  flex: 1;
  align-items: center;
  gap: 4px;
`;
const StatTitle = styled(LabelUppercase)`
  margin: 0;
  padding: 0;
`;

const StatValue = styled(H3)`
  margin: 0;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const DayLabel = styled(LabelUppercase)`
  margin: 0;
  padding: 0;
`;

const Circle = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
`;

const TodayCircle = styled(Circle)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
`;

const CompleteCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const PassedCircle = styled(Circle)`
  border: 1px solid ${({ theme }) => theme.colors.textRegular};
`;

const CheckMarkIcon = styled.i`
  padding-top: 2px;
  color: ${({ theme }) => theme.colors.white};
`;

export default function StreakCard() {
  const { value: streakData } = useStreakStats();

  return (
    <>
      {streakData ? (
        <Card>
          <TopRow>
            <StatCol>
              <StatValue>{streakData.streakDays}</StatValue>
              <StatTitle>STREAK</StatTitle>
            </StatCol>
            <StatCol>
              <StatValue>{streakData.maxStreak}</StatValue>
              <StatTitle>MAX. STREAK</StatTitle>
            </StatCol>
            <StatCol>
              <StatValue>{streakData.exercises}</StatValue>
              <StatTitle>EXERCISES</StatTitle>
            </StatCol>
            <StatCol>
              <StatValue>{streakData.aiMessages}</StatValue>
              <StatTitle>AI Q&A</StatTitle>
            </StatCol>
          </TopRow>
          <BottomRow>
            {streakData.dailyStats.map((streakValue, index) => {
              return (
                <StatCol key={streakValue.day + index}>
                  {streakValue.status === 'complete' && (
                    <CompleteCircle>
                      <CheckMarkIcon className={'fa-duotone fa-check'} />
                    </CompleteCircle>
                  )}
                  {streakValue.status === 'today' && (
                    <TodayCircle></TodayCircle>
                  )}
                  {streakValue.status === 'passed' && (
                    <PassedCircle></PassedCircle>
                  )}
                  <DayLabel>{streakValue.day}</DayLabel>
                </StatCol>
              );
            })}
          </BottomRow>
        </Card>
      ) : (
        <Card>
          <SkeletonLoaderWrapper>
            <ShimmerDiv />
            <>
              <TopRow>
                <StatCol>
                  <SkeletonDiv />
                </StatCol>
                <StatCol>
                  <SkeletonDiv />
                </StatCol>
                <StatCol>
                  <SkeletonDiv />
                </StatCol>
                <StatCol>
                  <SkeletonDiv />
                </StatCol>
              </TopRow>
              <BottomRow>
                {Array.from({ length: 7 }).map((_, index) => {
                  return (
                    <StatCol key={index}>
                      <PassedCircle />
                    </StatCol>
                  );
                })}
              </BottomRow>
            </>
          </SkeletonLoaderWrapper>
        </Card>
      )}
    </>
  );
}
