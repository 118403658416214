import React from 'react';
import PythonPlayground from '../components/PythonPlayground';
import styled from 'styled-components';
import PlaygroundPromoBtn from '../components/PlaygroundPromoBtn';
import { EVENTS, trackEvent } from '../analytics';

const PlaygroundContainer = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  overflow: hidden;
`;

export default function EmbeddablePythonPlayground() {
  const urlParams = new URLSearchParams(window.location.search);
  const isReadOnly = urlParams.get('readonly') === 'true';
  const startingCode = urlParams.get('startingcode');
  const [code, setCode] = React.useState(startingCode || '');

  const iframeHeight = window.innerHeight;

  const logRunToMixpanel = React.useCallback(() => {
    trackEvent({
      event: EVENTS.COMMON.RUN_EMBEDDED_PLAYGROUND,
      properties: {
        playgroundType: 'Python',
      },
    });
  }, []);

  return (
    <PlaygroundContainer height={iframeHeight}>
      <PythonPlayground
        code={code}
        onCodeChange={(code) => {
          setCode(code);
        }}
        onResult={logRunToMixpanel}
        onResetCode={() => {
          setCode('');
        }}
        isReadOnly={isReadOnly}
        headerText="Use the editor and run your code"
        shouldPreloadPyodide={false}
      />
      <PlaygroundPromoBtn />
    </PlaygroundContainer>
  );
}
