import React from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '../components/elements/Button';
import { useGetRetroSurvey } from '../hooks/use-hydra';
import { useParams } from 'react-router-dom';
import { postRetroSurvey } from '../hydra';
import useAuth from '../hooks/use-auth';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import PageWrapper from '../components/PageWrapper';
import PageWithAsyncData from '../components/PageWithAsyncData';
import Navbar from '../components/Navbar';
import { H1, LabelUppercase, P, PSmall } from '../components/elements/Text';
import { MultiLineInput } from '../components/elements/Input';
import { Helmet } from 'react-helmet-async';
import { getHomeUrl, getRetroSurveyPageTitle } from '../routing';
import * as CrashReporter from '../crash-reporter';
import {
  Checkbox as CheckboxOption,
  RadioButton,
} from '../components/elements/SelectionInputs';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(relativeTime);

const Section = styled.div``;

export default function RetroSurvey() {
  const { teamSlug } = useParams();
  const theme = useTheme();
  const { isLoading, getTokenSilently } = useAuth();
  const {
    value: retroSurvey,
    retry: refreshRetroSurvey,
    loading: retroSurveyIsLoading,
    error: retroSurveyError,
  } = useGetRetroSurvey(teamSlug);

  const [answers, setAnswers] = React.useState([]);

  React.useEffect(() => {
    setAnswers(
      (retroSurvey?.questions ?? []).map((x) => {
        // use existing answers, if they exist
        if (x.answer) {
          return x.answer;
        }

        // otherwise, place defaults
        if (x.type === 'RATING') {
          return null;
        }
        if (x.type === 'FREE_TEXT') {
          return '';
        }
        if (x.type === 'MULTI_SELECT') {
          return [];
        }
        return null;
      })
    );
  }, [retroSurvey]);

  const areAnswersFilledIn = answers.every((answer, i) => {
    const questionType = retroSurvey.questions[i].type;
    const isQRequired = retroSurvey.questions[i].required;
    if (!isQRequired) {
      return true;
    }

    if (questionType === 'RATING') {
      return answer !== null;
    }
    if (questionType === 'FREE_TEXT') {
      return answer.length > 0;
    }

    // don't check multi-select answers as choosing none is a valid answer
    return true;
  });

  const submitRetroSurvey = async () => {
    const survey = {
      ...retroSurvey,
      answeredAt: dayjs.utc().toDate(),
      questions: (retroSurvey?.questions ?? []).map((q, i) => ({
        ...q,
        answer: answers[i],
      })),
    };
    const token = await getTokenSilently();

    try {
      await postRetroSurvey(token, { survey, teamSlug });
      refreshRetroSurvey();
      toast.success('Successfully submitted the retro survey!');
    } catch (err) {
      CrashReporter.reportError(err);
      toast.error(
        'Something went wrong. Please reach out to us to resolve the issue.'
      );
    }
  };

  return (
    <PageWithAsyncData
      isLoading={isLoading || retroSurveyIsLoading}
      error={retroSurveyError}
      retry={refreshRetroSurvey}
    >
      {retroSurvey && (
        <>
          <Helmet>
            <title>{getRetroSurveyPageTitle()}</title>
          </Helmet>
          <Navbar
            breadCrumbs={[
              {
                label: 'Home',
                url: getHomeUrl(),
              },
              // todo: could add content title + link to dashboard here
              {
                label: 'Retro Survey',
                url: null,
              },
            ]}
          />
          <PageWrapper
            style={{
              margin: '0 auto 52px',
              maxWidth: theme.dimensions.readableColumnWidth,
            }}
          >
            <H1>Retro Survey</H1>
            <P>
              <b>
                Please take a moment to let us know about your Enki experience.
                Any feedback you share is confidential and greatly appreciated.
              </b>
            </P>
            {(retroSurvey?.questions ?? []).map((question, i) => (
              <RetroSurveyQuestion
                key={`retro-survey-question-${i}`}
                question={question}
                qIndex={i}
                answers={answers}
                setAnswers={setAnswers}
              />
            ))}
            <div style={{ marginTop: '52px' }}>
              <Button
                isPrimary
                onClick={submitRetroSurvey}
                isDisabled={!areAnswersFilledIn || retroSurvey?.answeredAt}
                label={retroSurvey?.answeredAt ? 'Submitted' : 'Submit'}
                type="secondary"
              />
            </div>
          </PageWrapper>
        </>
      )}
    </PageWithAsyncData>
  );
}

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

function RetroSurveyQuestion({ question, qIndex, answers, setAnswers }) {
  if (question.type === 'RATING') {
    return (
      <RatingQuestion
        question={question}
        qIndex={qIndex}
        answer={answers[qIndex] || null}
        setAnswer={(val) =>
          setAnswers(answers.map((_, i) => (i === qIndex ? val : _)))
        }
      />
    );
  }
  if (question.type === 'FREE_TEXT') {
    return (
      <FreeTextQuestion
        question={question}
        qIndex={qIndex}
        answer={answers[qIndex] || ''}
        setAnswer={(val) =>
          setAnswers(answers.map((_, i) => (i === qIndex ? val : _)))
        }
      />
    );
  }
  if (question.type === 'MULTI_SELECT') {
    return (
      <MultiSelectQuestion
        question={question}
        qIndex={qIndex}
        answer={answers[qIndex] || []}
        setAnswer={(val) =>
          setAnswers(answers.map((_, i) => (i === qIndex ? val : _)))
        }
      />
    );
  }
  return null;
}

function RatingQuestion({ question, qIndex, answer, setAnswer }) {
  const theme = useTheme();
  return (
    <Section>
      <P>
        {question.text}{' '}
        {question.required && (
          <span style={{ color: theme.colors.textDanger }}>*</span>
        )}
      </P>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          marginTop: '16px',
        }}
      >
        <LabelUppercase style={{ margin: 0 }}>Strongly Disagree</LabelUppercase>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {[1, 2, 3, 4, 5].map((i) => (
            <RatingContainer
              key={`rating-container-${qIndex}-${i}`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              <PSmall>{i}</PSmall>
              <RadioButton
                id={`${qIndex}-${i}`}
                checked={answer === i}
                onChange={() => setAnswer(i)}
                style={{ margin: 0 }}
              />
              <label htmlFor={`${qIndex}-${i}`} style={{ left: '25%' }}>
                {''}
              </label>
            </RatingContainer>
          ))}
        </div>

        <LabelUppercase style={{ margin: 0 }}>Strongly Agree</LabelUppercase>
      </div>
    </Section>
  );
}

function FreeTextQuestion({ question, answer, setAnswer }) {
  const theme = useTheme();

  return (
    <Section>
      <P>
        {question.text}{' '}
        {question.required && (
          <span style={{ color: theme.colors.textDanger }}>*</span>
        )}
      </P>
      <MultiLineInput
        placeholder="Your answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
    </Section>
  );
}

function MultiSelectQuestion({ question, qIndex, answer, setAnswer }) {
  const theme = useTheme();

  return (
    <Section>
      <P style={{ marginBottom: '32px' }}>
        {question.text}{' '}
        {question.required && (
          <span style={{ color: theme.colors.textDanger }}>*</span>
        )}
      </P>
      {question.options.map((option, i) => (
        <div
          key={`multi-select-container-${qIndex}-${i}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CheckboxOption
            id={`${qIndex}-${i}`}
            checked={answer.includes(option)}
            onChange={() =>
              setAnswer(
                answer.includes(option)
                  ? answer.filter((o) => o !== option)
                  : [...answer, option]
              )
            }
          />
          <label
            htmlFor={`${qIndex}-${i}`}
            style={{ left: '1em', top: '-0.5em' }}
          >
            {''}
          </label>
          <PSmall style={{ margin: 0 }}>{option}</PSmall>
        </div>
      ))}
    </Section>
  );
}
